<template>
  <div class="mt-4">
    <div v-if="isLoading" class="d-flex justify-content-center m-5">
      <div class="spinner-border text-primary">
        <span class="sr-only">{{$t('state.loading')}}</span>
      </div>
    </div>

    <div class="table-responsive">
      <b-table
          :busy="isLoading"
          striped show-empty
          :empty-text="$t('table.no_results')"
          hover
          head-variant="dark"
          outlined
          :no-local-sorting="true"
          :items="resourceTranslations"
          :fields="fields">

        <template #cell(actions)="data">
          <div class="table-action--cell">
            <router-link class="btn btn-primary btn-sm"
                         :to="{ name: 'cms-general', params: { id: data.item.id, locale: data.item.locale }}"> {{$t('cms.table.btn.edit')}}
            </router-link>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{$t('state.loading')}}</strong>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    isCreateType: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('cms.table.header.id'),
          sortable: true,
        },
        {
          key: 'keyName',
          label: this.$t('cms.table.header.key'),
          sortable: true
        },
        {
          key: 'locale',
          label: this.$t('cms.table.header.locale'),
          sortable: true
        },
        {
          key: 'slug',
          label: this.$t('cms.table.header.slug'),
          sortable: true
        },
        {
          key: 'title',
          label: this.$t('cms.table.header.title'),
          sortable: true
        },
        {key: 'actions', label: this.$t('cms.table.header.actions'),  sortable: false}
      ]
    }
  },
  mounted() {
    if(!this.isCreateType) {
      this.$store.dispatch('cms/getResourceTranslations', this.$route.params.id);
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      resourceTranslations: (state) => state.cms.resourceTranslations
    }),
  }
}
</script>